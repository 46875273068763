import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CamerasActions from '../actions/cameras_actions';

class CamerasBtn extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
  }

  handleClick = () => {
    this.props.actions.toggleCamerasList()
  }

  classies = () => {
    return "btn-show-list " + (this.props.visible ? "aopen" : "aclose");
  }

  render() {
    return (<div className={this.classies()} onClick={this.handleClick.bind(this)}>Камеры</div>)
  }
}

const mapStateToProps = state => ({
  visible: !state.visibleCamerasList
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CamerasActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CamerasBtn)
