import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CamerasActions from '../actions/cameras_actions';

import TimelineControl from './timeline_control';

class CamerasPlayer extends React.Component {
  static propTypes = {
    selectCameras: PropTypes.object
  }

  constructor (props) {
    super(props)
  }

  componentDidUpdate (previousProps, previousState) {
    //TODO: Это не правильно переделать
    if(this.props.selectCamera.id) {
      if(!this._player) this._player = this._createPlayer()
      this._player.src({type: "application/x-mpegUrl", src: this.createSrc()})
    }else{
      if(this._player) this._player.dispose();
      delete this._player
    }
  }

  createSrc () {
    const cam = this.props.selectCamera;

    if (cam.show_type === "flussonic") {
      const src = "https://" + cam.server_ip + ":" + cam.server_port + '/' + cam.url;
      if (this.props.selectCamera.archiveTime === undefined) {
        return src + "/index.m3u8";
      } else {
        return src + "/index-" + this.props.selectCamera.archiveTime + '-' + 1800 + ".m3u8";
      }
    } else {
      const src = "https://" + cam.server_ip + ":" + cam.server_port + "/" + cam.url
      if(this.props.selectCamera.archiveTime === undefined) {
        return src + "/playlist.m3u8"
      } else {
        return src + "/playlist_dvr_range-" + this.props.selectCamera.archiveTime + "-" +1800 + ".m3u8"
      }
    }

    return src;
  }

  handleClickClose() {
    this.props.actions.unselectCamera();
  }

  renderPlayer() {
    return (
      <video ref='player' id='html5' className='video-js vjs-default-skin'>
        <p className='vjs-no-js'>To view this video please enable JavaScript, and consider upgrading to a web browser that <a href='https://videojs.com/html5-video-support/' target='_blank'>supports HTML5 video</a></p>
      </video>
    )
  }

  renderForm() {
    return (
      <div className='player-block'>
        <div className="header-block">
          <a className="close" href="#" onClick={this.handleClickClose.bind(this)}>
            <i className="fa fa-times" />
          </a>
        </div>
        <div className="body-block ratio16-9">
          {this.renderPlayer()}
        </div>
        <div className='footer-block'>
          <TimelineControl/>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        { this.props.selectCamera.id ? this.renderForm() : null }
      </div>
    )
  }

  _createPlayer() {
    return videojs('html5', { controls: true, autoplay: true, width: 600, height: 450, techOrder: ['html5']})
  }
}

const mapStateToProps = state => ({
  selectCamera: state.selectCamera
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CamerasActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CamerasPlayer)
