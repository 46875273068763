import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as FeedbackActions from '../actions/feedback_actions';

class ContactsForm extends React.Component {
  static propTypes = {
    feedback: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const feedback = { name: this.refs.name.value, email: this.refs.email.value, content: this.refs.content.value }
    this.props.actions.createFeedback(feedback)
  }

  inputClass = (field) => {
    return (this.props.feedback.isFetching ? "input state-disabled" : (this.isError(field) ? "state-error" : ""))
  }

  textareaClass = (field) => {
    return (this.props.feedback.isFetching ? "state-disabled" : (this.isError(field) ? "state-error" : ""))
  }

  submitClass = () => {
    return "btn-u" + (this.props.feedback.isFetching ? " state-disabled" : "")
  }

  formClass = () => {
    return "sky-form contact-style" + (this.props.feedback.isComplited ? " submited" : "")
  }

  isError = (field) => {
    const errors = this.props.feedback.errors;

    return (errors && errors.hasOwnProperty(field));
  }

  renderError = (field) => {
    return (<em className="invalid">{this.props.feedback.errors[field][0]}</em>)
  }

  render () {
    return (
      <div>
        <div className='headline'>
          <h2>Форма обратной связи</h2>
        </div>
        <br/>
        <form id='sky-form3' className={this.formClass()} onSubmit={this.handleSubmit.bind(this)}>
          <fieldset className='no-padding'>
            <label>
              Имя <span className='color-red'>*</span>
            </label>
            <div className='row sky-space-20'>
              <div className='col-md-7 col-md-offset-0'>
                <div className={this.inputClass('name')}>
                  <input type='text' ref='name' name='name' disabled={this.props.feedback.isFetching} className='form-control' />
                </div>
                {this.isError('name') ? this.renderError('name') : null}
              </div>
            </div>
            <label>
              Email <span className='color-red'>*</span>
            </label>
            <div className='row sky-space-20'>
              <div className='col-md-7 col-md-offset-0'>
                <div className={this.inputClass('email')}>
                  <input type='email' ref='email' name='email' disabled={this.props.feedback.isFetching} className='form-control' />
                </div>
                {this.isError('email') ? this.renderError('email') : null}
              </div>
            </div>
            <label>
              Сообщение <span className='color-red'>*</span>
            </label>
            <div className='row sky-space-20'>
              <div className='col-md-11 col-md-offset-0'>
                <div className={this.textareaClass('content')}>
                  <textarea rows='8' ref='content' name='content' disabled={this.props.feedback.isFetching} className='form-control' />
                </div>
                {this.isError('content') ? this.renderError('content') : null}
              </div>
            </div>
            <p>
              <button type='submit' className={this.submitClass()} disabled={this.props.feedback.isFetching}>Отправить сообщение</button>
            </p>
          </fieldset>
          <div className='message'>
            <i className="rounded-x fa fa-check" />
            <p>Ваше сообщение успешно отправлено!</p>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  feedback: state.feedback
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(FeedbackActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsForm)
