import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CamerasActions from '../actions/cameras_actions';

class Cameras extends React.Component {
  static propTypes = {
    cameras: PropTypes.array.isRequired,
    selectCamera: PropTypes.object,
    actions: PropTypes.object.isRequired
  }

  handleClickCamera = (camera) => {
    this.props.actions.selectCamera(camera)
  }

  renderCamera = (camera) => {
    let className = "list-group-item";
    if (camera === this.props.selectCamera) className += " active"

    return (
      <li key={camera.id} className={className}><a href="#" onClick={this.handleClickCamera.bind(this, camera)}>{camera.full_name}</a></li>
    )
  }

  render () {
    return (
      <ul className="list-group sidebar-nav-v1 margin-bottom-40">
        {this.props.cameras.map(this.renderCamera)}
      </ul>
    )
  }
}

const mapStateToProps = state => ({
  cameras: state.cameras,
  selectCamera: state.selectCamera
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CamerasActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cameras)
