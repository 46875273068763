import React, { PropTypes } from 'react';

import CalculatorForm from './calculator_form';
import CalculatorResult from './calculator_result';

export default class TariffsPage extends React.Component {
  render () {
    return (
      <div>
        <CalculatorForm />
        <CalculatorResult />
      </div>
    )
  }
}
