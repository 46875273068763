import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CalculatorActions from '../actions/calculator_actions';

class CalculatorForm extends React.Component {
  static propTypes = {
    options: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  }

  handleSubmit (e) {
    e.preventDefault();

    const options = {
      porches: this.refs.porches.value,
      apartments: this.refs.apartments.value,
      stairwell_cameras: this.refs.stairwell_cameras.value,
      input_cameras: this.refs.input_cameras.value,
      checkout: this.refs.checkout.value,
      archive_depth: this.refs.archive_depth.value
    }
    this.props.actions.calculateTariffs(options);
  }

  isDisabled () {
    return this.props.options.isFetching;
  }

  submitClass () {
    return "btn-u" + (this.props.options.isFetching ? " state-disabled" : "")
  }

  inputClass = (field) => {
    return "input" + (this.props.options.isFetching ? " state-disabled" : (this.isError(field) ? " state-error" : ""))
  }

  isError = (field) => {
    const errors = this.props.options.errors;

    return (errors && errors.hasOwnProperty(field));
  }

  renderError = (field) => {
    return (this.isError(field) ? this.errorField(field) : null);
  }

  errorField = (field) => {
    return (<div className="note note-error">{this.props.options.errors[field][0]}</div>)
  }

  render () {
    const options = this.props.options;

    return (
      <div className="container content">
        <form className="sky-form tariff_options" onSubmit={this.handleSubmit.bind(this)}>
          <div className="row">
            <div className="col-md-12">
              <header>Расчет стоимости</header>
              <fieldset>
                <section>
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <label htmlFor='porches' className='label'>Количество подьездов</label>
                      <div className={this.inputClass('porches')}>
                        <input ref='porches' name='porches' type='number' min='1' defaultValue={options.porches}  disabled={this.isDisabled()}/>
                      </div>
                      {this.renderError('porches')}
                      <div className="note">Сколько подъездов готовы подключаться</div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <label htmlFor="apartments" className='label'>Количество квартир в доме</label>
                      <div className={this.inputClass('apartments')}>
                        <input ref='apartments' name='apartments' type='number' min='20' defaultValue={options.apartments}  disabled={this.isDisabled()}/>
                      </div>
                      {this.renderError('apartments')}
                      <div className="note">Сколько квартир в подключаемых подъездах</div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <label htmlFor="stairwell_cameras" className="label">Количество камер в подъезде</label>
                      <div className={this.inputClass('stairwell_cameras')}>
                        <input ref='stairwell_cameras' name='stairwell_cameras' type='number' min='0' defaultValue={options.stairwell_cameras}  disabled={this.isDisabled()}/>
                      </div>
                      {this.renderError('stairwell_cameras')}
                      <div className="note">Антикража, антивандализм, возможна установка на каждый этаж</div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <label htmlFor="input_cameras" className="label">Количество камер на входе</label>
                      <div className={this.inputClass('input_cameras')}>
                        <input ref="input_cameras" name="input_cameras" type="number" min='0' defaultValue={options.input_cameras}  disabled={this.isDisabled()}/>
                      </div>
                      {this.renderError('input_cameras')}
                      <div className="note">Безопасный вход и пространства у подьезда</div>
                    </div>
                  </div>
                </section>
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <label htmlFor="checkout" className="label">Камеры на въезд-выезд</label>
                      <div className={this.inputClass('checkout')}>
                        <input ref="checkout" name="checkout" type="number" min="0" defaultValue={options.checkout}  disabled={this.isDisabled()}/>
                      </div>
                      {this.renderError('checkout')}
                      <div className="note">Контроль вьезда, детской плащадки, отдельной парковки</div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <label htmlFor="archive_depth" className="label">Глубина архива</label>
                      <div className={this.inputClass('archive_depth')}>
                        <input ref="archive_depth" name="archive_depth" type="number" min="1" defaultValue={options.archive_depth}  disabled={this.isDisabled()}/>
                      </div>
                      {this.renderError('archive_depth')}
                      <div className="note">Сколько дней записи будет храниться на сервере</div>
                    </div>
                  </div>
                <section>
                </section>
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <footer>
                <button type="submit" className={this.submitClass()} disabled={this.isDisabled()}>Рассчитать</button>
              </footer>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  options: state.calculatorOptions
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CalculatorActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorForm)
