import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CamerasActions from '../actions/cameras_actions';

import Cameras from './cameras';
//import HlsPlayer from './hls_player';
import CamerasPlayer from './cameras_player';

class CamerasPage extends React.Component {
  static propTypes = {
    cameras: PropTypes.array.isRequired
  }
  constructor (props) {
    super(props)
    this.state = {
      style: {
        minHeight: '100px'
      },
      shouldComponentUpdate: true
    }
  }

  componentDidMount() {
    const minHeight = $(window).height() - $(this.refs.child).offset().top;
    let styles = Object.assign({}, this.state.styles);
    styles.minHeight = minHeight + 'px'

    this.setState({
      styles,
      shouldComponentUpdate: false
    });
  }

  shouldComponentUpdate () {
    return this.state.shouldComponentUpdate;
  }

  renderEmptyPage () {
    return (
      <div>
        <div className="container content-sm">
          <div className="headline-center margin-bottom-60" >
            <h1>У Вас нет доступных камер.</h1>
          </div>
        </div>
        <div className="container content-sm">
          <div className="margin-bottom-60" />
        </div>
        <div className="container content-sm">
          <div className="margin-bottom-60" />
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className="container content profile">
        <div className="row">
          <div className="col-md-4 md-margin-bottom-40">
            {this.props.cameras.length > 0 ? <Cameras /> : this.renderEmptyPage()}
          </div>
          <div className="col-md-8">
            <div ref="child" style={this.state.styles} className="profile-body">
              <CamerasPlayer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cameras: state.cameras
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CamerasActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CamerasPage)
