import loadApi from './loaders/load_api';

class ApiMap {
  constructor () {
    this.api = window.ymaps ? window.ymaps : null;
  }

  setAPI (instance) {
    this.api = instance;
    return this.api
  }

  getAPI () {
    return this.api;
  }

  isAvailible () {
    return Boolean(this.api);
  }

  /**
   * Loading API
   * @return {Promise}
   */

  load (options={}) {
    return loadApi({host: 'api-maps.yandex.ru', verstion: '2.1'}, options).then((instance) => {
      this.api = instance;
      return instance;
    });
  }
}

export default new ApiMap();
