import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import * as reducers from '../reducers';
import CamerasPage from '../components/cameras_page';

const reducer = combineReducers(reducers);
const store   = createStore(reducer, window.storePage, applyMiddleware(thunk));

export default class CamerasPageContainer extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <CamerasPage />
      </Provider>
    )
  }
}
