import React, { PropTypes } from 'react';

class MapElement extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      styles: {
        width: '100%',
        height: '800px',
        margin: 0,
        padding: 0
      },
      shouldComponentUpdate: true
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  shouldComponentUpdate() {
    return this.state.shouldComponentUpdate;
  }

  render () {
    return (
      <div ref="child" style={this.state.styles}></div>
    );
  }

  updateDimensions() {
    const height = ($(window).height() - $(this.refs.child).offset().top) + 'px';

    let styles = Object.assign({}, this.state.styles);
    let shouldComponentUpdate = false;

    if(styles.height != height) {
      shouldComponentUpdate = true;
      styles.height = height;
    }

    this.setState({
      styles,
      shouldComponentUpdate: shouldComponentUpdate
    });
  }
}

export default MapElement;
