import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CalculatorActions from '../actions/calculator_actions';
import numeral from '../numeral-ru';

class CalculatorResult extends React.Component {
  static propTypes = {
    tariffs: PropTypes.array
  }

  renderPanel = (tariff, index) => {
    return (
      <div key={index} className="panel panel-default">
        <div className="panel-heading">
          <h4 className="panel-title">
            <a className="accordion-toggle" data-parent="#accordion" data-toggle="collapse" href={"#collapse-" + (index+1)}>Тариф: {tariff.name}</a>
          </h4>
        </div>
        <div className={"panel-collapse collapse" + (index === 0 ? " in" : "")} id={"collapse-" + (index+1)}>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Наименование</th>
                      <th className="specifications">Характеристики</th>
                      <th className="cost">Цена, руб.</th>
                      <th>Кол-во</th>
                      <th>Сумма, руб.</th>
                    </tr>
                  </thead>
                  <tbody>
                    { tariff.items.map(this.renderTableRow) }
                  </tbody>
                </table>
                <br />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Общая</th>
                      <th>На квартиру</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{textAlign: 'right'}}><b>Стоимость проекта:</b></td>
                      <td>{numeral(tariff.total).format('0,0[.]00 $')}</td>
                      <td>{numeral(tariff.total_for_appartment).format('0,0[.]00 $')}</td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'right'}}><b>Стоимость обслуживания:</b></td>
                      <td>{numeral(tariff.cost_service).format('0,0[.]00 $')}</td>
                      <td>{numeral(tariff.cost_service_for_appartment).format('0,0[.]00 $')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTableRow (item, index) {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td className="specifications">{item.description}</td>
        <td className="cost">{item.cost}</td>
        <td>{item.amount}</td>
        <td>{item.sum}</td>
      </tr>
    )
  }

  render () {
    return (
      <div className="container content">
        <div className="row">
          <div className="col-md-12">
            <div className="panel-group acc-v1" id="accordion" aria-multiselectable="true">
              { this.props.tariffs.map(this.renderPanel) }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tariffs: state.tariffs
})

export default connect(
  mapStateToProps
)(CalculatorResult)
