import React from 'react';
import ContactsForm from './contacts_form';

export default class ContactsPage extends React.Component {
  render () {
    return (
      <ContactsForm />
    )
  }
}
