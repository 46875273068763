import { CALCULATOR_REQUEST, CALCULATOR_SUCCESS, CALCULATOR_FAILURE } from '../constants/action_types';

const calculatorOptions = (state = { isFetching: false }, action) => {
  switch(action.type) {
    case CALCULATOR_REQUEST:
      return {
        ...state,
        ...action.options,
        isFetching: true,
        errors: []
      }
    case CALCULATOR_SUCCESS:
      return {
        ...state,
        ...action.option,
        isFetching: false
      }
    case CALCULATOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.errors
      }
    default:
      return state
  }
}

export default calculatorOptions
