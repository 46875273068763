import React, { PropTypes } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CamerasActions from '../actions/cameras_actions';

import MapElement from './map_element';
import MapController from '../controllers/map_controller';
import api from '../utils/api_map';

class MapContainer extends React.Component {
  static propTypes = {
    cameras: PropTypes.array.isRequired,
    visibleList: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props);
  }
  componentDidMount() {
    if(api.isAvailible()) {
      this._onAPILoad(api.getAPI());
    } else {
      api.load({})
        .then(this._onAPILoad.bind(this))
        .catch((error) => console.log('Error occured: %s', error));
    }
  }

  render () {
    return (
      <div>
        <MapElement ref="mapContainer" />
      </div>
    )
  }

  handleSelectById (id) {
    const camera = $.grep(this.props.cameras, (camera) => { return camera.id === id })[0];
    if (camera) {
      this.props.actions.selectCamera(camera)
      if(!this.props.visibleList) this.props.actions.toggleCamerasList()
    }
  }

  _onAPILoad (namespace) {
    this.props.onAPIAvailable && this.props.onAPIAvailable(namespace);

    this._controller = new MapController();
    this._controller.createMap(
      ReactDOM.findDOMNode(this.refs.mapContainer),
      {
        center: [51.7185,94.4359],
        zoom: 15,
        controls: []
      },
      this.handleSelectById.bind(this)
    );
    this._controller.appendObjects(this.props.cameras);

    this.setState({isAPILoaded: true});
  }
}

const mapStateToProps = state => ({
  cameras: state.cameras,
  visibleList: state.visibleCamerasList,
  isAPILoaded: false
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CamerasActions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapContainer)
