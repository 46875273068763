import { CALCULATOR_SUCCESS } from '../constants/action_types';

const tariffs = (state = {}, action) => {
  switch(action.type) {
    case CALCULATOR_SUCCESS:
      return action.result
    default:
      return state
  }
}

export default tariffs
