import { FEEDBACK_REQUEST, FEEDBACK_SUCCESS, FEEDBACK_FAILURE } from '../constants/action_types';

const feedbackRequest = feedback => ({ type: FEEDBACK_REQUEST, feedback: feedback })
const feedbackSuccess = feedback => ({ type: FEEDBACK_SUCCESS, feedback: feedback })
const feedbackFailure = errors   => ({ type: FEEDBACK_FAILURE, errors: errors })

export const createFeedback = feedback => {
  return dispatch => {
    dispatch(feedbackRequest(feedback))
    return $.ajax({
      method: 'POST',
      url: '/feedbacks',
      data: { feedback: feedback },
      dataType: 'json',
    }).done( (data) => {
      dispatch(feedbackSuccess(data))
    }).fail( (jqXHR, textStatus, error) => {
      if(jqXHR.status === 422){
        dispatch(feedbackFailure(jqXHR.responseJSON))
      } else {
        $.jGrowl("Сервер временно не доступен.", { sticky: true, header: "Ошибка", theme: "alert alert-danger", themeState: "" });
      }
    })
  }
}
