import { ADD_CAMERA } from '../constants/action_types';

const cameras = (state = [], action) => {
  switch (action.type) {
    case ADD_CAMERA:
      return [
        ...state,
        action.camera
      ]
    default:
      return state
  }
}

export default cameras
