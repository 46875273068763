import { SELECT_CAMERA, UNSELECT_CAMERA, PLAY_ARCHIVE } from '../constants/action_types';

const selectCamera = (state = {}, action) => {
  switch (action.type) {
    case SELECT_CAMERA:
      return action.camera
    case UNSELECT_CAMERA:
      return {}
    case PLAY_ARCHIVE:
      return Object.assign({}, state, {archiveTime: action.archiveTime})
    default:
      return state
  }
}

export default selectCamera
