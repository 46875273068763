import api from '../utils/api_map';

// const iconSvg = '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32" width={{ options.size[0] }} height={{ options.size[1] }}><defs><path d="M31.71 16.05C31.71 7.4 24.7 0.38 16.05 0.38C7.41 0.38 0.39 7.4 0.39 16.05C0.39 24.69 7.41 31.71 16.05 31.71C24.7 31.71 31.71 24.69 31.71 16.05Z" id="h1KtLmPt8u"></path></defs><g><g><g><use xlink:href="#h1KtLmPt8u" opacity="1" fill={{ options.color }} fill-opacity="1"></use></g></g></g></svg>';

export default class MapController {
  constructor () {
  }

  createMap (container, state, action) {
    const ymaps = api.getAPI()

    this._map = new ymaps.Map(container, state)
    this._map.controls.add(this._createZoomControl())

    // const icon = ymaps.templateLayoutFactory.createClass(iconSvg)
    // ymaps.option.presetStorage.add('dvor#green', {
    //   iconLayout: icon,
    //   iconColor: '#72c02c',
    //   iconSize: [20, 20],
    //   iconOffset: [-10, -20]
    // })

    this._setupObjectManager(action);

    return this;
  }

  appendObjects (objects) {
    const data = {
      type: 'FeatureCollection',
      features: objects.map( (object) => {
        return {
          type: "Feature",
          id: object.id,
          geometry: {
            type: "Point",
            coordinates: [object.latitude, object.longitude]
          },
          properties: {
            hintContent: object.full_name
          }
        }
      })
    }

    this._objectManager.add(data);
  }

  get map () {
    return this._map;
  }

  setOptions (name, value) {
    this._map.options.set(name, value);
  }

  setCenter (coords) {
    this._map.setCenter(coords);
  }

  setZoom (zoom) {
    this._map.setZoom(zoom);
  }

  setState (name, value) {
    this._map.state.set(name, value);
  }

  destroy () {
    this._map.destroy();
  }

  _setupObjectManager (action) {
    this._objectManager = new (api.getAPI()).ObjectManager({
      clusterize: true,
      gridSize: 32
    });
    // this._objectManager.objects.options.set('preset', 'dvor#green');
    this._objectManager.objects.options.set('preset', 'islands#greenDotIcon');
    this._objectManager.clusters.options.set('preset', 'islands#greenClusterIcons');

    function onObjectEvent (e) {
      action(e.get('objectId'));
    }
    this._objectManager.objects.events.add(['click'], onObjectEvent);

    this._map.geoObjects.add(this._objectManager);
  }

  _createZoomControl () {
    let ZoomLayout = (api.getAPI()).templateLayoutFactory.createClass(
      "<div id='zoom-controls'><div id='zoom-in' class='btn'><i class='fa fa-plus'></i></div><div id='zoom-out' class='btn'><i class='fa fa-minus'></i></div></div>",
      {
        build: function() {
          ZoomLayout.superclass.build.call(this);

          this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
          this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

          $('#zoom-in').bind('click', this.zoomInCallback);
          $('#zoom-out').bind('click', this.zoomOutCallback);
        },
        clear: function() {
          $('#zoom-in').unbind('click', this.zoomInCallback);
          $('#zoom-out').unbind('click', this.zoomOutCallback);

          ZoomLayout.superclass.clear.call(this);
        },
        zoomIn: function() {
          var map = this.getData().control.getMap();

          this.events.fire('zoomchange', {
            oldZoom: map.getZoom(),
            newZoom: map.getZoom() + 1
          });
        },
        zoomOut: function() {
          var map = this.getData().control.getMap();

          this.events.fire('zoomchange', {
            oldZoom: map.getZoom(),
            newZoom: map.getZoom() - 1
          });
        }
      });
    return new (api.getAPI()).control.ZoomControl({ options: { layout: ZoomLayout } });
  }

  _createLayersControl() {
    let LayersLayout = (api.getAPI()).templateLayoutFactory.createClass(
      "<div id='layer-controls'><div id='layer-header'>Слои</div></div>",
      {
        build: function() {
        },
        clear: function() {
        },
      }
    )

    return null
  }
}
