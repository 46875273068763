/* eslint-disable */

import "bootstrap-sass";
import "../src/site/js/back-to-top";
import "../src/site/js/owl.carousel";
import "jgrowl/jquery.jgrowl.min";

import "../src/site/scss/application";

require.context('../images', true);

import React from 'react';
import ReactDOM from 'react-dom';
import clearStorePage from "../src/site/js/utils/store_methods";
import MapPageContainer from "../src/site/js/containers/map_page_container";
import ContactsPageContainer from "../src/site/js/containers/contacts_page_container";
import CamerasPageContainer from "../src/site/js/containers/cameras_page_container";
import TariffsPageContainer from "../src/site/js/containers/tariffs_page_container";

// Fixed Header
function handleHeader() {
  jQuery(window).scroll(function() {
    if (jQuery(window).scrollTop() > 100) {
      jQuery('.header-fixed .header-sticky').addClass('header-fixed-shrink');
    } else {
      jQuery('.header-fixed .header-sticky').removeClass('header-fixed-shrink');
    }
  });
}

// Equal Height Columns
function handleEqualHeightColumns() {
  var EqualHeightColumns = function () {
    $(".equal-height-columns").each(function() {
      var heights = [];
      $(".equal-height-column", this).each(function() {
        $(this).removeAttr("style");
        heights.push($(this).height()); // write column's heights to the array
      });
      $(".equal-height-column", this).height(Math.max.apply(Math, heights)); //find and set max
    });
  }

  EqualHeightColumns();
  $(window).resize(function() {
    EqualHeightColumns();
  });
  $(window).on('load', function() {
    EqualHeightColumns();
  });
}

function handleBootstrap() {
  jQuery('.tooltips').tooltip();
  jQuery('.tooltips-show').tooltip('show');
  jQuery('.tooltips-hide').tooltip('hide');
  jQuery('.tooltips-toggle').tooltip('toggle');
  jQuery('.tooltips-destroy').tooltip('destroy');
}

function renderComponent(component, element) {
  if($(element).length) {
    ReactDOM.render(
        React.createElement(component),
        $(element)[0]
    )
  }
}

function handleMethod(link) {
  var href = link[0].href,
      method = link.data('method'),
      target = link.attr('target'),
      csrfToken = $('meta[name=csrf-token]').attr('content'),
      csrfParam = $('meta[name=csrf-param]').attr('content'),
      form = $('<form method="post" action="' + href + '"></form>'),
      metadataInput = '<input name="_method" value="' + method + '" type="hidden" />';

  if (csrfParam !== undefined && csrfToken !== undefined) {
    metadataInput += '<input name="' + csrfParam + '" value="' + csrfToken + '" type="hidden" />';
  }

  if (target) { form.attr('target', target); }

  form.hide().append(metadataInput).appendTo('body');
  form.submit();
}

jQuery(document).ready(function(){
  handleHeader();
  handleEqualHeightColumns();
  handleBootstrap();

  renderComponent(MapPageContainer, '#map-page');
  renderComponent(CamerasPageContainer, '#cameras-page');
  renderComponent(ContactsPageContainer, '#contacts-page');
  renderComponent(TariffsPageContainer, '#tariffs-page');

  $('a[data-method]').on('click', function(e) {
    e.preventDefault();
    var link = $(this);
    handleMethod(link);
  });

  //Owl Clients v1
  jQuery(".owl-clients-v1").owlCarousel({
      items : 7,
      autoPlay : 5000,
      itemsDesktop : [1000,5],
      itemsDesktopSmall : [900,4],
      itemsTablet: [600,3],
      itemsMobile : [300,2]
  });

  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });

  videojs.options.flash.swf = "/video-js.swf"
  videojs.addLanguage('ru', {
    "Play": "Воспроизвести",
    "Pause": "Приостановить",
    "Current Time": "Текущее время",
    "Duration Time": "Продолжительность",
    "Remaining Time": "Оставшееся время",
    "Stream Type": "Тип потока",
    "LIVE": "ОНЛАЙН",
    "Loaded": "Загрузка",
    "Progress": "Прогресс",
    "Fullscreen": "Полноэкранный режим",
    "Non-Fullscreen": "Неполноэкранный режим",
    "Mute": "Без звука",
    "Unmute": "Со звуком",
    "Playback Rate": "Скорость воспроизведения",
    "Subtitles": "Субтитры",
    "subtitles off": "Субтитры выкл.",
    "Captions": "Подписи",
    "captions off": "Подписи выкл.",
    "Chapters": "Главы",
    "You aborted the media playback": "Вы прервали воспроизведение видео",
    "A network error caused the media download to fail part-way.": "Ошибка сети вызвала сбой во время загрузки видео.",
    "The media could not be loaded, either because the server or network failed or because the format is not supported.": "Невозможно загрузить видео из-за сетевого или серверного сбоя либо формат не поддерживается.",
    "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "Воспроизведение видео было приостановлено из-за повреждения либо в связи с тем, что видео использует функции, неподдерживаемые вашим браузером.",
    "No compatible source was found for this media.": "Совместимые источники для этого видео отсутствуют."
  });

  clearStorePage();
});