import { CALCULATOR_REQUEST, CALCULATOR_SUCCESS, CALCULATOR_FAILURE } from '../constants/action_types';

const calculatorRequest = options => ({ type: CALCULATOR_REQUEST, options: options })
const calculatorSuccess = result => ({ type: CALCULATOR_SUCCESS, result: result })
const calculatorFailure = errors  => ({ type: CALCULATOR_FAILURE, errors: errors })

export const calculateTariffs = options => {
  return dispatch => {
    dispatch(calculatorRequest(options))
    return $.ajax({
      method: 'GET',
      url: '/tariffs',
      data: { options: options },
      dataType: 'json'
    }).done( (data) => {
      dispatch(calculatorSuccess(data))
    }).fail( (jqXHR, textStatus, error) => {
      if(jqXHR.status === 422){
        dispatch(calculatorFailure(jqXHR.responseJSON));
      } else {
        $.jGrowl("Сервер временно не доступен.", { sticky: true, header: "Ошибка", theme: "alert alert-danger", themeState: "" });
      }
    })
  }
}
