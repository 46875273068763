import React from 'react';

import CamerasBtn from './cameras_btn';
import CamerasList from './cameras_list';
//import HlsPlayer from './hls_player';
import CamerasPlayer from './cameras_player';
import MapContainer from './map_container';

export default class MapPage extends React.Component {
  render () {
    return (
      <div>
        <MapContainer />
        <CamerasBtn />
        <CamerasList />
        <CamerasPlayer />
      </div>
    )
  }
}
