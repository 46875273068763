export const ADD_CAMERA      = 'ADD_CAMERA';
export const SELECT_CAMERA   = 'SELECT_CAMERA';
export const UNSELECT_CAMERA = 'UNSELECT_CAMERA';
export const TOGGLE_CAMERA_LIST = 'TOGGLE_CAMERA_LIST';

export const PLAY_ARCHIVE       = 'PLAY_ARCHIVE';

export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
export const FEEDBACK_FAILURE = 'FEEDBACK_FAILURE';

export const CALCULATOR_REQUEST = 'CALCULATOR_REQUEST';
export const CALCULATOR_SUCCESS = 'CALCULATOR_SUCCESS';
export const CALCULATOR_FAILURE = 'CALCULATOR_FAILURE';
