import { TOGGLE_CAMERA_LIST } from '../constants/action_types';

const visibleCamerasList = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CAMERA_LIST:
      return !state
    default:
      return state
  }
}

export default visibleCamerasList
