import { FEEDBACK_REQUEST, FEEDBACK_SUCCESS, FEEDBACK_FAILURE } from '../constants/action_types';

const feedback = (state = { body: {}, isFetching: false, isComplited: false }, action) => {
  switch(action.type) {
    case FEEDBACK_REQUEST:
      return {
        ...state,
        isFetching: true,
        body: action.feedback,
        errors: []
      }
    case FEEDBACK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isComplited: true,
        body: action.feedback
      }
    case FEEDBACK_FAILURE:
      return {
        ...state,
        isFetching: false,
        isComplited: false,
        errors: action.errors
      }
    default:
      return state
  }
}

export default feedback
