import fetchScript from './fetch_script';

let loadPromise;

const enabledAPIParams = ['lang', 'apikey', 'coordorder', 'load', 'mode'];
const successCallbackName = '_$_api_success';
const errorCallbackName = '_$_api_error';

const defaultOptions = {
  lang: 'ru_Ru',
  onload: successCallbackName,
  onerror: errorCallbackName
}

function generateURL (apiConfig, options) {
  const params = Object.assign({}, defaultOptions);
  Object.keys(options)
    .filter((key) => enabledAPIParams.indexOf(key) !== -1)
    .forEach((key) => {
      params[key] = options[key];
    });

  const queryString = Object.keys(params).reduce((result, key) => {
    result.push(key + '=' + params[key]);
    return result;
  }, []).join('&');

  return 'https://' + apiConfig.host + '/' + apiConfig.verstion + '/?' + queryString;
}

export default function loadApi (apiConfig, options) {
  if (loadPromise) {
    return loadPromise;
  }

  loadPromise = new Promise((resolve, reject) => {
    window[successCallbackName] = (ymaps) => {
      resolve(ymaps);
      window[successCallbackName] = null;
    };

    window[errorCallbackName] = (error) => {
      reject(error);
      window[errorCallbackName] = null;
    }

    fetchScript(generateURL(apiConfig, options));
  });

  return loadPromise;
}
