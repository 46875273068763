import React, { PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CamerasActions from '../actions/cameras_actions';

class CamerasList extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    cameras: PropTypes.array.isRequired,
    selectCamera:  PropTypes.object,
    actions: PropTypes.object.isRequired
  }

  handleClickCamera = (camera) => {
    this.props.actions.selectCamera(camera)
  }

  handleClickClose = () => {
    this.props.actions.unselectCamera()
    this.props.actions.toggleCamerasList()
  }

  renderCamera = (camera) => {
    let className = "";
    if (camera.id === this.props.selectCamera.id) className += "active"

    return (
      <li key={camera.id}><a href='#' className={className} onClick={this.handleClickCamera.bind(this, camera)} >{camera.full_name}</a></li>
    )
  }

  classies = () => {
    return "cameras-list " + (this.props.visible ? "aopen" : "aclose");
  }

  render () {
    return (
      <div className={this.classies()}>
        <div className="header-block">
          Камеры
          <a className="close" href="#" onClick={this.handleClickClose.bind(this)}>
            <i className="fa fa-times" />
          </a>
        </div>
        <div className="list-block">
          <ul id="cameras" className="list-unstyled">
            {this.props.cameras.map(this.renderCamera)}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  visible: state.visibleCamerasList,
  cameras: state.cameras,
  selectCamera: state.selectCamera
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CamerasActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CamerasList)
