import React, {PropTypes} from 'react'
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CamerasActions from '../actions/cameras_actions';

import moment from 'moment';
import 'moment/locale/ru';
import 'ion-rangeslider/js/ion.rangeSlider';
moment.locale('ru');

class TimelineControl extends React.Component {
  static propTypes = {
    selectCamera: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      activeDay: moment(),
      activeMonth: moment().format("MMMM"),
      daysObject: {},
      onFinish: false //TODO: remove this hack
    }
  }

  componentDidMount() {
    let daysObject = {}
    for(let i=this.props.selectCamera.duration_archive; i >= 0; i--) {
      let date = moment().add(-i, 'days'),
          month = date.format("MMMM")
      if(daysObject[month] === undefined) {
        daysObject[month] = []
      }

      daysObject[month].push(date)
    }
    this.setState({daysObject: daysObject})

    let day       = moment(),
        startTime = day.clone().startOf('day'),
        endTime   = day.clone().endOf('day')

    $(ReactDOM.findDOMNode(this.refs.timelineSlider)).ionRangeSlider({
      from: +day.format("X"),
      min: +startTime.format("X"),
      max: +endTime.format("X"),
      from_min: +startTime.format("X"),
      from_max: +day.format("X"),
      from_shadow: true,
      grid: true,
      force_edges: true,
      prettify: (num) => {
        let m = moment(num, "X").locale("ru")
        return m.format("HH:mm")
      },
      onFinish: (data) => {
        if(this.state.onFinish) {
          this.sendPlayArchive(moment.unix($(data.input).val()))
        } else {
          this.setState({onFinish: true})
        }
      }
    })
  }

  componentDidUpdate() {
    if(this.props.selectCamera.archiveTime === undefined) {
      let day       = moment(),
          startTime = day.clone().startOf('day'),
          endTime   = day.clone().endOf('day')

      let slider = $(ReactDOM.findDOMNode(this.refs.timelineSlider)).data("ionRangeSlider")
      slider.update({
        from: +day.format("X"),
        min: +startTime.format("X"),
        max: +endTime.format("X"),
        from_min: +startTime.format("X"),
        from_max: +day.format("X"),
      })
    }
  }

  handleChangeDay(date) {
    this.setState({activeDay: date})

    let  currTime  = moment.unix(ReactDOM.findDOMNode(this.refs.timelineSlider).value),
        day = date.hours(currTime.hours()).minutes(currTime.minutes()),
        startTime = day.clone().startOf('day'),
        endTime   = day.clone().endOf('day'),
        fromMin   = moment().add(-this.props.selectCamera.duration_archive*24*60, "minute"),
        fromMax   = moment()

    this.sendPlayArchive(day)

    if(fromMin.format("DD.MM.YYYY") != day.format("DD.MM.YYYY")){
      fromMin = startTime.clone()
    }

    if(fromMax.format("DD.MM.YYYY") != day.format("DD.MM.YYYY")){
      fromMax = endTime.clone()
    }

    let slider = $(ReactDOM.findDOMNode(this.refs.timelineSlider)).data("ionRangeSlider")
    slider.update({
      from: +day.format("X"),
      min: +startTime.format("X"),
      max: +endTime.format("X"),
      from_min: +fromMin.format("X"),
      from_max: +fromMax.format("X"),
    })
  }

  sendPlayArchive(date) {
    this.props.actions.playArchive(date.unix())
  }

  handleChangeMonth(month) {
    this.setState({activeMonth: month})
  }

  prevMonth() {
    let array = Object.keys(this.state.daysObject)
    let index = array.indexOf(this.state.activeMonth)
    index--
    if(index < 0)
      index = array.length-1

    this.setState({activeMonth: array[index]})
  }

  nextMonth() {
    let array = Object.keys(this.state.daysObject)
    let index = array.indexOf(this.state.activeMonth)
    index++
    if(index >= array.length)
      index = 0

    this.setState({activeMonth: array[index]})
  }

  render () {
    return (
      <div className="timeline-controls">
        {this.renderDaysBlock()}
        <div className="separator"/>
        <div className="timeline">
          <input ref="timelineSlider" id="timeline-slider"/>
        </div>
        <div className="clearfix"/>
      </div>
    )
  }

  renderDaysBlock() {
    return (
      <div className="days">
        <div className="days-header">
          <div className="months-left" onClick={this.prevMonth.bind(this)}><i className="fa fa-chevron-circle-left"/></div>
          <div className="months">
            {this.renderDaysBlockHeader()}
          </div>
          <div className="months-right" onClick={this.nextMonth.bind(this)}><i className="fa fa-chevron-circle-right"/></div>
        </div>
        <div className="days-body">
          {Object.keys(this.state.daysObject).map((item, i) => {
              return (
                  <div key={i} className={"row" + (this.isActiveMonth(item) ? " active" : "")}>
                    {this.renderDaysOfMonth(item)}
                  </div>
                )
            })
          }
        </div>
      </div>
    )
  }

  renderDaysBlockHeader() {
    return Object.keys(this.state.daysObject).map((item, i) => {
      return (<div key={i} className={this.isActiveMonth(item) ? "active" : null} onClick={this.handleChangeMonth.bind(this, item)}>{item}</div>)
    })
  }

  renderDaysOfMonth(month) {
    return this.state.daysObject[month].map((item, i) => {
      return (<div key={i} className="col-md-1 col-sm-1 col-xs-2">
          <div className={"day" + (this.isActiveDay(item) ? " active" : "")} onClick={this.handleChangeDay.bind(this, item)}>{item.format("D")}</div>
        </div>)
    })
  }

  isActiveMonth(month) {
    return this.state.activeMonth === month
  }

  isActiveDay(date) {
    return this.state.activeDay.date() === date.date() && this.state.activeDay.month() === date.month()
  }
}

const mapStateToProps = state => ({
  selectCamera: state.selectCamera
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CamerasActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimelineControl)
